import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ZoomInfoHeaderWrapper from './zoominfoHeader.style';
import BlueCheck from '../../../../common/assets/image/pricing/blue-tick.svg';
import { Link } from 'gatsby';

const ZoomInfoHeader = () => {
  const ZoominfoPageData = useStaticQuery(graphql`
    {
      allStrapiAffiliatePageImage {
        nodes {
          img1 {
            localFile {
              publicURL
            }
          }
          img2 {
            localFile {
              publicURL
            }
          }
          img3 {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);

  const img = ZoominfoPageData.allStrapiAffiliatePageImage.nodes[0];

  return (
    <>
      <ZoomInfoHeaderWrapper>
        <h2 className="heading-of-table">
          Here's What <span className="heading-span">Awaits You:</span>
        </h2>
      </ZoomInfoHeaderWrapper>
      <ZoomInfoHeaderWrapper>
        <table>
          <tbody>
            <tr>
              <td>
                <div>
                  <h3>
                    <img className="blue-tick" src={BlueCheck} />
                    Unmatched Lead Intelligence
                  </h3>
                  <p>
                    Tap into our vast database of verified B2B contacts,
                    enriched with AI-powered insights to help you identify your
                    ideal prospects.
                  </p>
                </div>
              </td>
              <td>
                <div>
                  <h3>
                    <img className="blue-tick" src={BlueCheck} />
                    Effortless Prospecting
                  </h3>
                  <p>
                    Forget manual searches and endless scrolling. Our AI engine
                    finds your perfect leads, saving you hours and maximizing
                    your outreach.
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <h3>
                    <img className="blue-tick" src={BlueCheck} />
                    Personalized Outreach
                  </h3>
                  <p>
                    Craft compelling emails and build automated sales sequences
                    tailored to each prospect's unique needs.
                  </p>
                </div>
              </td>
              <td>
                <div>
                  <h3>
                    <img className="blue-tick" src={BlueCheck} />
                    Data-Driven Decisions
                  </h3>
                  <p>
                    Track your progress, analyse performance, and optimize your
                    campaigns with actionable insights from our reporting
                    dashboard.
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <h3>
                    <img className="blue-tick" src={BlueCheck} />
                    Seamless integrations
                  </h3>
                  <p>
                    Connect Clodura.AI with your favourite CRM, email marketing
                    platform, and other tools for a streamlined sales process.
                  </p>
                </div>
              </td>
              <td>
                <div>
                  <h3>
                    <img className="blue-tick" src={BlueCheck} />
                    Real-time analytics
                  </h3>
                  <p>
                    Track your progress, measure the impact of your campaigns,
                    and optimize your strategy based on data-driven insights.
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ZoomInfoHeaderWrapper>
    </>
  );
};

export default ZoomInfoHeader;
