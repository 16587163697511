import styled from 'styled-components';

const ZoomInfoHeaderWrapper = styled.section`
  display: flex;
  padding: 20px;
  margin: auto;
  width: 90%;

  button {
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    width: 16rem;
    height: 56px;
    cursor: pointer;
    :hover {
      background-color: #edcd37;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 420px) {
      font-size: 12px;
      width: 100%;
      height: 35px;
    }
  }

  .text {
    h4 {
      font-size: 24px;
    }
    @media screen and (max-width: 768px) {
      width: 90%;
      > h1 {
        font-size: 26px;
      }
      > p {
        font-size: 12px;
      }
    }
  }

  .heading-of-table {
    font-size: 42px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: -600px;
    font-weight: 400;
    border-radius: 10px;
    border: 2px solid #000;
    padding: 10px 25px;

    @media screen and (max-width: 435px) {
      margin: 0;
      font-size: 22px;
      /* width: 100%; */
      /* height: 35px; */
    }
  }

  .heading-span {
    color: #03baed;
    font-weight: 600;
  }

  .bottom-heading {
    font-size: 42px;
    font-weight: 400;
    @media screen and (max-width: 435px) {
      font-size: 12px;
    }
  }

  .bottom-span {
    font-size: 52px;
    font-weight: 600;
    @media screen and (max-width: 435px) {
      font-size: 12px;
    }
  }

  p {
    /* padding: 20px; */
    color: #767676;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    padding-right: 40px;
    /* padding-right: 20px; */
    @media screen and (max-width: 435px) {
      font-size: 12px;
      /* width: 100%;
      height: 35px; */
    }
  }

  h3 {
    font-size: 26px;
    margin-bottom: -10px;
    font-weight: 500;
    @media screen and (max-width: 420px) {
      font-size: 16px;
      padding-right: 0;
      /* width: 100%;
      height: 35px; */
    }
  }

  .blue-tick {
    width: 25px;
    height: 25px;
    margin-left: -30px;
    margin-right: 5px;
  }

  table {
    width: 90%;
    @media screen and (max-width: 435px) {
      margin-left: 20px;
      gap: 20px;
      td {
        padding: 0 10px; /* Adjust this value for desired spacing */
      }
    }
  }

  td {
    /* padding-right: 25px; */
    /* border: 1px solid #ddd; */
  }

  tr {
    margin-bottom: -20px;
  }

  .section1,
  .section2,
  .section3 {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .section1,
  .section2,
  .section3 {
    justify-content: space-between;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-evenly;

    .section1,
    .section2,
    .section3 {
    }
  }
`;

export default ZoomInfoHeaderWrapper;
