import React, { Fragment } from 'react';
import Heading from 'common/components/Heading';
import Box from 'common/components/Box';

import VideosContainer from './videosforsignup.style';
import PropTypes from 'prop-types';

const SignupSuccessDemoVideo = ({ title }) => {
  return (
    <>
      <VideosContainer>
        <h2 className="heading-of-table">
          Watch Our
          <span className="heading-span"> Demo Videos</span>
        </h2>
      </VideosContainer>

      <VideosContainer>
        <Box>
          <iframe
            width="450"
            height="315"
            src="https://www.youtube-nocookie.com/embed/I2BRW3HYRPE?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Box>
        <Box>
          <iframe
            width="450"
            height="315"
            src="https://www.youtube-nocookie.com/embed/xdkAOOdJikw?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Box>
        <Box>
          <iframe
            width="450"
            height="315"
            src="https://www.youtube-nocookie.com/embed/Lp24p8eTOHI?controls=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Box>
      </VideosContainer>
    </>
  );
};

SignupSuccessDemoVideo.propTypes = {
  title: PropTypes.object,
};

SignupSuccessDemoVideo.defaultProps = {
  title: {
    fontSize: ['24px', '30px', '30px', '40px', '40px'],
    fontWeight: '600',
    textAlign: 'center',
  },
};

export default SignupSuccessDemoVideo;
