import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ZoomInfoHeaderWrapper from './zoominfoHeader.style';
import LeftImg from '../../../../common/assets/image/saasModern/WelcomeToClodura/WelcomeLeftSide.svg';
import RightImg from '../../../../common/assets/image/saasModern/WelcomeToClodura/WelcomeRightSide.svg';

import { Link } from 'gatsby';

const ZoomInfoHeader = () => {
  const ZoominfoPageData = useStaticQuery(graphql`
    {
      allStrapiWebsiteFormEnrichmentThankYouPage {
        nodes {
          img {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `);

  const img =
    ZoominfoPageData.allStrapiWebsiteFormEnrichmentThankYouPage.nodes[0];

  return (
    <ZoomInfoHeaderWrapper>
      <img className="left-bg" src={LeftImg} alt="Left Background" />
      <img className="right-bg" src={RightImg} alt="Right Background" />

      <div className="text">
        <h1>
          Demo <span style={{ color: '#03BAED' }}>Booked!</span>
        </h1>
        <hr className="hr" />
        <h2>You’re All Set!</h2>

        <p>
          We're looking forward to speaking with you!
          <br />
          <br />
          In the meantime, feel free to check out our free database and some of
          our recent blogs.
        </p>
      </div>

      <img
        className="right-img"
        src={
          ZoominfoPageData.allStrapiWebsiteFormEnrichmentThankYouPage.nodes[0]
            .img.localFile.publicURL
        }
      />
    </ZoomInfoHeaderWrapper>
  );
};

export default ZoomInfoHeader;
