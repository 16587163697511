import styled from 'styled-components';

const VideosContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* margin: 0 auto; */
  /* background-color: #2b70f5; */
  /* margin-top: 70px; */
  margin-bottom: 30px;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }

  div {
    width: fit-content;
    padding: 20px;
    height: fit-content;
    background-color: white;
    box-shadow: 0 0 10px 0 lightgray;
    margin-bottom: 10px;
  }

  .heading-of-table {
    font-size: 34px;
    margin-top: 30px;
    margin-bottom: 0;
    /* margin-left: -800px; */
    font-weight: 400;
    border-radius: 10px;
    border: 2px solid #000;
    padding: 8px 20px;

    @media screen and (max-width: 435px) {
      margin: 0;
      font-size: 22px;
      /* width: 100%; */
      /* height: 35px; */
    }
  }

  .heading-span {
    color: #03baed;
    font-weight: 600;
  }

  .bottom-heading {
    font-size: 34px;
    font-weight: 400;
    @media screen and (max-width: 435px) {
      font-size: 22px;
      text-align: center;
      padding: 10px;
      margin-bottom: 10px;
    }
  }

  .bottom-span {
    font-size: 52px;
    font-weight: 600;
    @media screen and (max-width: 435px) {
      font-size: 28px;
    }
  }

  button {
    margin-top: -50px;
    margin-bottom: 50px;
    color: white;
    background-color: #03baed;
    border: none;
    font-weight: 600;
    font-family: Poppins;
    font-size: 24px;
    border-radius: 5px;
    width: 160px;
    height: 50px;
    cursor: pointer;
    :hover {
      background-color: #edcd37;
      color: black;
      transition: all 0.3s ease 0s;
    }

    @media screen and (max-width: 420px) {
      font-size: 12px;
      width: 100%;
      height: 35px;
    }
  }

  @media screen and (max-width: 1024px) {
    iframe {
      width: 400px;
      height: 225px;
    }
  }
  @media screen and (max-width: 650px) {
    iframe {
      width: 100%;
      height: 100%;
    }
  }
`;

export default VideosContainer;
